<script setup>
import {computed, onMounted, onUnmounted, ref, reactive} from "vue";
import {useToast} from "primevue/usetoast";
import {useVuelidate} from "@vuelidate/core";
import {required} from "@vuelidate/validators";
import {storeToRefs} from "pinia";
import {MemberStore} from "@/uhas/store/member";

const toast = useToast();
const memberStore = MemberStore();

const {memberData} = storeToRefs(memberStore);

const infoState = reactive({
	provider: 'exness',
	account_ea: null,
	platform: 'MT4',
});
const infoValidation = useVuelidate({
	account_ea: { required },
	provider: { required },
	platform: { required },
}, infoState);
const infoSubmitted = ref(false);

/**
 * Data
 */
const loading = ref(false);

/**
 * Computed
 */
const requestList = computed(() => {
	if (memberData.value.ea_requests) {
		// const list = Array.from(memberData.value.ea_requests);
		const list = Object.keys(memberData.value.ea_requests).map((key) => memberData.value.ea_requests[key]).filter(item => !!item);
		list.sort((a,b) => (a.created_at < b.created_at) ? 1 : ((b.created_at < a.created_at) ? -1 : 0));

		return list;
	}

	return [];
});

/**
 * Mounted
 */
onMounted (() => {

});

onUnmounted(() => {

})

/**
 * Actions
 */
const requestFreeEA = async () => {
	if (infoValidation.value.$invalid) {
		return
	}

	loading.value = true;
	const result = await memberStore.requestFreeEA(infoState)
		.then(result => result)
		.catch(err => err)
		.finally(() => {
			loading.value = false;
		});

	if (result.error) {
		toast.add({
			severity:"error",
			summary: "Error Message",
			detail: result.error.message || "Something went wrong please contact administrator.",
			life: 10000
		});
	} else {
		toast.add({
			severity:"success",
			summary: "Success Message",
			detail: "Your EA request has been subbmited.",
			life: 10000
		});
	}
}
</script>

<style lang="scss" scoped>

</style>

<template>
	<Toast />
	<Card>
		<template #title>
      แบบฟอร์มรับ EA Uhas Zone ฟรี
    </template>
		<template #content>
			<div class="w-full md:w-30rem">
				EA Uhas Zone เป็นโปรแกรม Robot Trade ที่พัฒนามาจากทีมโค้ช Uhas โดยเฉพาะ เพื่อสมาชิก Uhas member ได้เข้าถึงเครื่องมือช่วยในการเทรดทอง ทำให้การเทรดทองสะดวกและง่ายมากยิ่งขึ้น ก่อนใช้งานอย่าลืมอ่านคู่มือการใช้งานที่
				<a href="https://uhas.com/ea-uhas-zone" target="_blank">uhas.com/ea-uhas-zone</a>
				โดยสมาชิก 1 คน สามารถขอ EA ได้มากกว่า 1 ตัว และสามารถขอได้เรื่อยๆ แต่บัญชีที่ขอต้องอยู่ในพาร์ทเนอร์ของ Uhas และ EA ตัวดังกล่าวต้องใช้กับโปรแกรม MetaTrader 4 เท่านั้น โดยโบรกเกอร์ที่ผ่านการทดสอบแล้วคือโบรกเกอร์ exness
			</div>

			<div class="my-6 field w-max max-w-full">
				<div class="p-inputgroup">
					<Dropdown
						v-model="infoValidation.provider.$model"
						class="w-7rem"
						:options="['exness', 'xm']"
						placeholder="Provider"
					/>
					<InputText
						v-model="infoValidation.account_ea.$model"
						:class="{'p-error': infoValidation.account_ea.$invalid && infoSubmitted}"
						class="w-15rem"
						placeholder="เลขบัญชีเทรดที่ต้องการใช้ EA"
					/>
					<Dropdown
						v-model="infoValidation.platform.$model"
						class="w-7rem"
						:options="['MT4', 'MT5']"
						placeholder="Platform"
					/>
					<Button
						:loading="loading"
						:disabled="loading"
						icon="pi pi-caret-right"
						class="p-button-outlined"
						label="ส่งคำขอ"
						@click="requestFreeEA"
					/>
				</div>
				<small v-if="infoValidation.account_ea.$invalid && infoSubmitted" class="p-error">
					{{infoValidation.account_ea.required.$message.replace("Value", "เลขบัญชี EA")}}
				</small>
			</div>

			<template
				v-for="(item, i) in requestList"
				:key="`request-${i}`"
			>
				<div
					v-if="item"
					class="mb-4"
				>
					<div>
						เลขบัญชี {{ item.account_ea }}
						คำขอ {{ (item.created_at || '').split(' ')[0] }}
						<span :style="`${item.status === 'accepted' ? 'color: green;' : (item.status === 'rejected' ? 'color: red;' : 'color: grey;') }`">
							สถานะ{{ item.status === 'accepted' ? 'อนุมัติแล้ว' : (item.status === 'rejected' ? 'ปฏิเสธ' : 'กำลังดำเนินการ') }}
						</span>
					</div>
					<div
						v-for="(file, j) in JSON.parse(item.files || '[]')"
						:key="`ea-file-${i}-${j}`"
					>
						<a :href="file" target="_blank">ดาวน์โหลดไฟล์ EA ของคุณ ({{ file }})</a>
					</div>
					<div class="text-orange-500">
						{{ item.note }}
					</div>
				</div>
			</template>
		</template>
	</Card>
</template>
